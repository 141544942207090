<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="600px">
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-card>
          <v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" height="100px">
          </v-img>
          <v-card-title>
            <span class="headline">{{$t('dialogs.clone-payer.clone-payer')}}: {{copyObjectData.payerName}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-text-field v-model="clonedObject.payerName" :label="$t('dialogs.clone-payer.payer')" clearable/>
                    <span class="red--text font-weight-light">{{ errors[0] }}</span>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-autocomplete v-model="agencyId" :items="agencies" item-value="agencyId"
                                    :label="$t('dialogs.clone-payer.agency')"
                                    item-text="agencyName">
                    </v-autocomplete>
                    <span class="red--text font-weight-light">{{ errors[0] }}</span>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="clonedObject.comment" :label="$t('dialogs.clone-payer.comments')" clearable/>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text v-on:click="$emit('close-dialog')">
              {{$t('buttons.cancel')}}
            </v-btn>
            <v-btn color="primary" v-on:click="$emit('clone-object',clonedObject)" :disabled="invalid">
              {{$t('buttons.clone')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
  </v-row>
</template>

<script>
  import {ValidationObserver, ValidationProvider, extend} from "vee-validate";
  import {required} from 'vee-validate/dist/rules';

  extend('required', {
    ...required,
    message: 'The field is required'
  });

  export default {
    props: ['open-dialog', 'agencyId', 'agencies', 'payer'],
    name: "ClonePayerDialog",
    components: {ValidationObserver, ValidationProvider},
    data: () => ({
      clonedObject: [],
    }),
    computed: {
      copyObjectData() {
        return this.clonedObject = this.payer;
      }
    },
  }
</script>

<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
