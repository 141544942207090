<template>
  <v-container id="payers" fluid tag="section">
    <ClonePayerDialog v-bind:open-dialog="cloneDialog" v-on:close-dialog="cloneDialog=false"
                      :agencyId="agency.agencyId" :agencies="agencies" :payer="payer"
                      v-on:clone-object="clonePayer($event)"></ClonePayerDialog>
    <DeleteConfirmationDialog :object-name="'payer'" :payer="payer"
                              v-on:close-dialog="deleteDialog=false" v-bind:open-dialog="deleteDialog"
                              v-on:delete-object="deleteObject"></DeleteConfirmationDialog>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row class="ma-auto">
              <v-col>
                <div class="display-2 font-weight-light" style="text-transform: uppercase">
                  <h4>{{payer.payerName}}</h4>
                </div>
              </v-col>
              <v-col>
                <router-link :to="{path: '/agency/' + agency.agencyId}">
                  <div class="display-2 font-weight-light"
                       style="float: right; text-transform: uppercase; color: white">
                    <h4>[{{agency.agencyName}} -- {{agency.city}}]</h4>
                  </div>
                </router-link>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="ma-auto">
              <v-col>
                <MenuDropdown></MenuDropdown>
              </v-col>
              <v-col>
                <ActionButtonGroup v-on:enable-edit="readonly=false" v-on:open-delete-dialog="openDeleteDialog()"
                                   v-on:clone-dialog="cloneDialog=true"></ActionButtonGroup>
              </v-col>
            </v-row>
          </template>

          <validation-observer
            ref="observer"
            v-slot="{ invalid }">
            <v-form @submit.prevent="update">
              <v-container fluid>
                <v-row dense>
                  <v-tabs grow icons-and-text center-active centered show-arrows>
                    <v-tab v-for="card in cards" :key="card.id">{{card.title}}
                      <v-icon :color="card.color">{{card.icon}}</v-icon>
                    </v-tab>
                    <v-tab-item>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12" md="6">
                            <validation-provider rules="required" v-slot="{ errors }">
                              <v-text-field required
                                            :label="$t('payer-card.payer')" v-model="payer.payerName"
                                            :readonly="readonly"/>
                              <span class="red--text font-weight-light">{{ errors[0] }}</span>
                            </validation-provider>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              :label="$t('payer-card.agency')" v-model="agency.agencyName" readonly/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-tab-item>


                    <v-tab-item>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12" md="12">
                            <validation-provider v-slot="{ errors }">
                              <v-text-field :label="$t('payer-card.comments')" v-model="payer.comment"
                                            :readonly="readonly"/>
                              <span class="red--text font-weight-light">{{ errors[0] }}</span>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-tab-item>

                    <v-tab-item>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              :label="$t('payer-card.created-date')" v-model="createdTime" readonly/>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              :label="$t('payer-card.created-date')" v-model="modifiedTime" readonly/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-tab-item>
                  </v-tabs>
                </v-row>

                <v-row>
                  <v-col
                    cols="6"
                    class="text-right">
                    <v-btn color="primary" class="mr-0" @click="disabled=true" block :disabled="disabled"
                           :loading="loading">
                      {{$t('buttons.cancel')}}
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    class="text-right">
                    <v-btn color="primary" class="mr-0" @click="update" :disabled="readonly" block>
                      {{$t('buttons.update')}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </validation-observer>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="4">
        <base-material-card color="primary" icon="mdi-sofa" class="v-card-profile">
          <template v-slot:heading>
            <div class="display-2 font-weight-light" style="text-transform: uppercase">
              {{payer.payerName}}
            </div>
            <div class="subtitle-1 font-weight-light" style="text-transform: uppercase">
              {{agency.agencyName}} -- {{agency.city}}
            </div>
          </template>
          <v-card-text class="text-center">
            <h4 class="display-2 font-weight-light mb-3 black--text" style="text-transform: uppercase">
              {{payer.payerName}}
            </h4>
            <h6 class="display-1 mb-1 grey--text" style="text-transform: uppercase">
              {{agency.agencyName}} -- {{agency.city}}
            </h6>
            <v-divider></v-divider>
          </v-card-text>

          <v-row justify="center" class="ma-auto">
            <v-expansion-panels popout focusable>
              <v-expansion-panel>
                <v-expansion-panel-header>{{$t('payer-card.orders')}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <OrderRelatedRecords :payerId="payer.payerId" :objectName="objectName"></OrderRelatedRecords>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  import OrderRelatedRecords from "../../components/expansion-panels/OrderRelatedRecords";
  import ClonePayerDialog from "./ClonePayerDialog";
  import ActionButtonGroup from "../../components/buttons/ActionButtonGroup";
  import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
  import {required} from 'vee-validate/dist/rules';
  import MenuDropdown from "../dashboard/component/MenuDropdown";
  import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";
  import {useAgencies} from "../../stores/agenciesStore";
  import {usePayers} from "../../stores/payersStore";
  import {mapState} from "pinia";

  const agencyStore = useAgencies()
  const payerStore = usePayers()

  extend('required', {
    ...required,
    message: 'The field is required'
  });

  let moment = require('moment');
  export default {
    name: "PayerCard",
    components: {
      DeleteConfirmationDialog,
      MenuDropdown,
      ActionButtonGroup,
      OrderRelatedRecords,
      ClonePayerDialog,
      ValidationProvider,
      ValidationObserver
    },
    data() {
      return {
        loading: false,
        cards: [
          {id: 1, title: this.$t('payer-card-tabs.main-info'), color: 'indigo', flex: 12, icon: 'mdi-folder-information-outline'},
          {id: 2, title: this.$t('payer-card-tabs.comments'), color: 'pink darken-3', flex: 12, icon: 'mdi-comment-processing-outline'},
          {id: 3, title: this.$t('payer-card-tabs.system-info'), color: 'purple darken-4', flex: 12, icon: 'mdi-table-cog'},
        ],
        deleteDialog: false,
        cloneDialog: false,
        panelNames: ['Orders'],
        payer: [],
        agency: [],
        objectName: 'isPayer',
        disabled: true,
        readonly: true,
        files: [],
        createdTime: '',
        modifiedTime: '',
        fileCreatedTime: '',
        fileModifiedTime: '',
      }
    },
    methods: {
      parseTime() {
        this.createdTime = moment(this.payer.createdTime).format("DD.MM.YYYY, HH:mm");
        this.modifiedTime = moment(this.payer.modifiedTime).format("DD.MM.YYYY, HH:mm");
      },
      getEntities() {
        this.agency = this.payer.agency;
      },
      getPayerById() {
        this.loading = true;
        let params = new URLSearchParams();
        params.append('userId', this.currentUser.id);
        AXIOS.get('/api/payer/' + this.$route.params.payerId, {params})
          .then(response => {
            this.payer = response.data;
            this.getEntities();
            this.parseTime();
            this.loading = false;
          })
          .catch(e => {
            this.errors.push(e)
          })
      },
      openDeleteDialog() {
        this.deleteDialog = true;
      },
      deleteObject() {
        let action = 'delete';
        let objectType = 'Payer';
        let params = new URLSearchParams();
        params.append('payerId', this.payer.payerId);
        AXIOS.delete('/api/deletePayer', {params})
          .then(response => {
            payerStore.deletePayer(this.payer.payerId)
            this.userNotification(response.data, action, objectType);
          })
        this.$router.push({name: 'All Payers'})
      },
      update() {
        this.loading = true;
        let action = 'update';
        let objectType = 'Payer';
        let payer = this.payer;
        let params = new URLSearchParams();
        params.append('userId', this.currentUser.id);
        params.append('payerId', payer.payerId);
        params.append('payerName', payer.payerName);
        params.append('comment', payer.comment);
        AXIOS.put(`/api/updatePayer`, params)
          .then(response => {
            payerStore.patchPayer(response.data);
            this.userNotification(response, action, objectType);
            this.readonly = true;
          })
        this.loading = false;
      },
      clonePayer(clonedPayer) {
        this.loading = true;
        let action = 'clone';
        let objectType = 'Payer';
        let params = new URLSearchParams();
        params.append('payerName', clonedPayer.payerName);
        params.append('agencyId', clonedPayer.agency.agencyId);
        params.append('payerId', this.payer.payerId);
        AXIOS.post(`/api/clonePayer`, params)
          .then(response => {
            payerStore.addPayer(response.data);
            this.cloneDialog = false;
            this.userNotification(response, action, objectType);
            this.$router.push({path: '/payer/' + response.data.payerId})
          })
        this.loading = false;
      },
      updateNeededCheck() {
        if (!this.agencies.length) {
          agencyStore.getActiveAgencies()
        }
      },
    },
    computed: {
      ...mapState(useAgencies, ['agencies']),
    },
    created() {
      this.getPayerById();
      this.updateNeededCheck()
    },
  }
</script>
<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
